/*
Copyright (C) 2016 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/
/* 
    Created on : 08/12/2016, 15:38:45
    Author     : Jordi Santacana <jordistc@nautalogica.com>
*/
@import "variables";
@import "mixins";

html,
body {
	position: relative;
	height: 100%;
}

#wrapper {
	min-height: 100%;
	margin-bottom: -($footer-height + $footer-margin-top); 

	&:after {
		content: " ";
		display: table;
		overflow: hidden;
		height: $footer-height;
	}
}

#header {
	position: relative;
}

#footer {
	margin-top: $footer-margin-top;
	height: $footer-height;
}

#content {
	@include clearfix();
	margin-top: 20px;
	margin-bottom: 15px;
	padding: 10px 0;
	position: relative;
}
