#header {
	.widget-idiomes {
		float: right;
		margin-right: 15px;
		margin-bottom: 5px;
	}
	
	h1 {
		margin: 0;
		font-size: 24px;
	}

	.navbar {
		margin-bottom: 0;
	}
}
