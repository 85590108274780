@import "base/mixins/gradients";


.ntlgpanel-push-left {
	#signup-page {
		
		.ntlgsidepanel {
			width: 60%;
		}
		
		.ntlgpanel-push {
			@include transition-duration(.4s);
			@include translateX(60%);
		}
	}
}

#signup-page {
	color: #fff;

	#wrapper {
		background-image: url('../img/rider-id-bkimg.jpg');
		background-position: center center;
		background-size: cover;

		&::before {
			@include gradient-directional(rgba(143, 187, 71, 0.8), rgba(38, 180, 224, 0.8), -180deg);
			content: " ";
			display: block;
			height: 100%;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
		}
	}

	#header {
		color: #fff;

		a  {
			color: #fff;
		}

		.dropdown-menu a {
			color: #333;
		}
	}

	.form-submit {
		text-align: right;
	}
}

.signup-card{
	color: #333;
    background-color: #fff;

    border-radius: 8px;
    margin: 20px 0 70px;
	padding: 30px;

    min-height: 400px;

	/*	label {
			margin-top: 15px;
		}*/

	.btn {
		margin-top: 10px;
	}

	.acceptacio,
	.avislegal {
		margin: 5px 0;
	}

	.form-footer  {

		.form-submit  {
			text-align: center;
		}
	}
}



.signup-card .forgot{
    text-align: center;
}
