/*
Copyright (C) 2017 Nauta Tecnològica, SCP - All Rights Reserved.
Unauthorized copying, publishing, commercial usage and/or distribution of this file,
via any medium, is strictly prohibited without the express permission of the owner.
*/

#page-footer {
	@include clearfix();

	height: $footer-height;

	padding: 10px 0;
	background-color: transparent;

	h1 {
		font-size: 20px;
        margin: 0 0 5px 0;
    }

    h2 {
		font-size: 18px;
        margin: 10px 0 5px 0;
    }

	.nav-list > a {
		margin: 0 10px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

    .social {
        margin-top: 20px;
    }

	.site-info {
		font-size: 12px;
	}
    p,
    address {
        margin: 0;
    }
}